<template>
  <div v-if="showEndOfStageClientFeedbackTool" class="feedback-tool">
    <client-feedback-thank-you
      v-if="clientSavedFeedback"
      :show-thank-you="showThankYou"
    />
    <div v-else-if="processedClientFeedback" class="feedback-container">
      <div class="initial-interaction-container">
        <span class="prompt">
          {{ text.userPrompt }}
        </span>
        <div class="options-container">
          <div
            class="option thumbs-up"
            :class="{ 'selected': clientFeedbackChoice === 'thumbs-up' }"
            @click="handleOptionClick('thumbs-up')"
          >
            <font-awesome-svg
              :type="'thumbs-up'"
              :fill="getFillColor('thumbs-up')"
              class="icon"
            />
          </div>
          <div class="option-divider" />
          <div
            class="option thumbs-down"
            :class="{ 'selected': clientFeedbackChoice === 'thumbs-down' }"
            @click="handleOptionClick('thumbs-down')"
          >
            <font-awesome-svg
              :type="'thumbs-down'"
              :fill="getFillColor('thumbs-down')"
              class="icon"
            />
          </div>
        </div>
      </div>
      <div v-if="clientFeedbackChoice !== ''">
        <feedback-response-section :source-category="sourceCategory" />
      </div>
    </div>
    <ct-centered-spinner v-if="!processedClientFeedback" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FeedbackTool',
  components: {
    FontAwesomeSvg:           () => import('@/components/shared/FontAwesomeSvg'),
    CtCenteredSpinner:        () => import('@/components/shared/CtCenteredSpinner'),
    ClientFeedbackThankYou:   () => import('@/components/StagelineV2/clientFeedback/ClientFeedbackThankYou'),
    FeedbackResponseSection:  () => import('@/components/StagelineV2/clientFeedback/FeedbackResponseSection'),
  },
  props: {
    sourceCategory: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      text: {
        userPrompt: 'Was this stage helpful?',
        gaveFeedback: 'Thank you for the feedback!',
      },
      showThankYou: false,
    }
  },
  computed: {
    ...mapGetters('stagelineClientFeedback', [
      'processedClientFeedback',
      'currentClientFeedbackObj',
      'clientFeedbackSources',
      'clientFeedbackChoice',
      'clientSavedFeedback',
      'currentStageId',
      'showEndOfStageClientFeedbackTool',
    ]),
    fillColors() {
      return {
        'thumbs-up': this.clientFeedbackChoice === 'thumbs-up' ? 'white' : '#2fb2ae',
        'thumbs-down': this.clientFeedbackChoice === 'thumbs-down' ? 'white' : '#2fb2ae',
      }
    },
  },
  watch: {
    currentStageId() {
      this.populateFeedbackResources()
    },
    clientSavedFeedback(newValue) {
      if(newValue) this.toggleThankYou()
    },
  },
  mounted() {
    this.populateFeedbackResources()
  },
  methods: {
    ...mapActions('stagelineClientFeedback', [
      'setCurrentClientFeedbackByStageId',
      'initializeClientFeedbackValues',
      'setClientFeedbackChoice',
      'updateClientFeedback',
      'saveClientFeedback',
    ]),
    populateFeedbackResources() {
      this.setCurrentClientFeedbackByStageId()
      this.initializeClientFeedbackValues()
    },
    async handleOptionClick(option) {
      this.setClientFeedbackChoice(option)

      const params = {
        liked: this.clientFeedbackChoice === 'thumbs-up',
        source_category: this.sourceCategory,
      }

      this.currentClientFeedbackObj ?
        await this.updateClientFeedback(params) :
        await this.saveClientFeedback(params)
    },
    getFillColor(option) {
      return this.fillColors[option]
    },
    async toggleThankYou() {
      this.showThankYou = true
      await new Promise(resolve => setTimeout(() => {
        this.showThankYou = false
        resolve()
      }, 4000))
    },
  },
}
</script>

<style scoped lang="scss">
$gray2-light2: #D3D3D3;

.feedback-tool {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;

  .feedback-container {
    display: flex;
    flex-direction: column;

    .initial-interaction-container {
      display: flex;
      align-items: center;
      margin-top: 1em;
      margin-bottom: 1.5em;

      .prompt {
        margin-right: 2rem;
      }
      .options-container {
        display: grid;
        grid-template-columns: 1fr 1px 1fr;
        border: 1px solid $gray2-light2;
        border-radius: $stageline-primary-border-radius;

        .option {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.5em;
          height: 2.875em;
          width: 4.875em;

          .icon {
            display: flex;
            width: 25px;
          }
          &:hover {
            cursor: pointer !important;
          }
          &.selected {
            background-color: $ct-ui-primary;
            border-top-left-radius: $stageline-primary-border-radius;
            border-bottom-left-radius: $stageline-primary-border-radius;
          }
        }
        .thumbs-down {
          transform: scaleX(-1);
        }
        .option-divider {
          height: 100%;
          background: $gray2-light2;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .feedback-tool {
    padding-bottom: 1em;
  }
}
</style>
